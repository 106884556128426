import { useStaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import truncate from '../../helpers/truncate'
import AsideUpcomingCourses from '../AsideUpcomingCourses'

const SubLayout = ({children}) => {
  const data = useStaticQuery(graphql`
    query SubLayoutComponentQuery {
      sanitySiteSettings {
        quickLinks {
          title
          slug {
            current
          }
          url
        }
      }

      allSanityArticle(sort: {fields: _createdAt, order: DESC}, limit: 3) {
        nodes {
          title
          slug { current }
          content {
            children {
              text
            }
          }
        }
      }
    }
  `)

  const quickLinks = data.sanitySiteSettings.quickLinks
  const articles = data.allSanityArticle.nodes

  const articleElements = articles.map(({title, slug, content}) => {
    const stringContent = content.map(item => item.children[0]?.text).join(' ')

    return (
      <article className="article-card" key={slug.current}>
        <Link to={`/${slug.current}/`}>
          <h3>{title}</h3>
          <p>{truncate(stringContent, 125)}</p>
        </Link>
      </article>
    )
  })

  return (
    <StyledSubLayout>
      <main>
        {children}
      </main>
      <aside>
        <div className="aside-contents">
          <section>
            <h4>Upcoming Courses</h4>
            <AsideUpcomingCourses />
          </section>

          {quickLinks && quickLinks.length > 0 && (
            <section className="quick-links">
              <h4>Quick Links</h4>
              <ul>
                {quickLinks.map(({title, slug, url}) => (
                  slug?.current 
                  ? <li key={slug.current}><Link to={`/${slug.current}/`}>{title}</Link></li>
                  : <li key={url}><a href={url}>{title}</a></li>
                ))}
              </ul>
            </section>
          )}

          <section className="latest-news">
            <h4>Latest Mass College News</h4>
            {articleElements}
          </section>
        </div>
      </aside>
    </StyledSubLayout>
  )
}

const StyledSubLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(200px, 400px);
  max-width: 1600px;
  margin: auto;

  & > main {
    padding: 6rem 4rem 4rem 4rem;
  }

  aside {
    /* width: clamp(200px, 400px, 500px); */
    /* margin: 4rem 0; */
    /* padding: 4rem 2rem; */
    
    /* position: sticky;
    top: 4rem; */
    
    .aside-contents {
      max-height: calc(100vh - 8rem);
      margin: 6rem 0 4rem 0;
      padding: 0 2rem;
      border-left: solid 2px #eee;
      position: sticky;
      top: 4rem;
      overflow-y: scroll;

      
      &:after {
        /* content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% - 2rem);
        height: 2rem;
        background: red; */
        /* background: rgb(255,255,255); */
        /* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%); */
      }
    }

    section {
      margin-bottom: 1.5rem;
    }

    a {
      text-decoration: none;
    }

    h4 {
      background-color: var(--primary-color);
      color: #f4f4f4;
      padding: 1rem;
      margin-bottom: 1rem;
      font-size: 1.1rem;
      line-height: 1;
    }

    p, ul {
      /* margin-bottom: 2rem; */
    }

    ul {
      list-style: none;

      a {
        text-decoration: none;
      }
    }

    section.quick-links {
      a {
        font-weight: bold;

        &:hover {
          color: var(--primary-color);
        }
      }

      ul {
        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    section.latest-news {
      h3 {
        margin-bottom: 0.25rem;
        font-size: 0.95rem;
        line-height: 1.4;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.15;
      }

      article.article-card {
        margin-bottom: 0.75rem;

        &:hover {
          h3 {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    /* grid-template-columns: 1fr 315px; */

    & > main {
      padding: 4rem 2rem;
    }
  }

  @media (max-width: 768px) {
    display: initial;

    aside {
      display: none;
    }
  }

  @media (max-width: 480px) {
    & > main {
      padding: 2rem 1rem;
    }
  }
`

export default SubLayout
